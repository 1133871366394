/* eslint no-console: 0 */
// Run this example by adding <%= javascript_pack_tag 'hello_vue' %> (and
// <%= stylesheet_pack_tag 'hello_vue' %> if you have styles in your component)
// to the head of your layout file,
// like app/views/layouts/application.html.erb.
// All it does is render <div>Hello Vue</div> at the bottom of the page.

import Vue from 'vue/dist/vue.esm';
//import TurbolinksAdapter from "vue-turbolinks";
import VueResource from 'vue-resource';
import NotificationComponent from '../components/NotificationComponent'
//import App from '../app.vue'
Vue.use(VueResource);
Vue.config.productionTip = false;
Vue.config.devtools = false;
//DOMContentLoaded
document.addEventListener('DOMContentLoaded', () => {
	//Vue.http.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
	Vue.http.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
	var element = document.getElementById("app");
	//var app2;

	if (element != null) {
		 app2 = new Vue({
			el:element,
			created:function(){
				//this.getNotifications();
				this.auxToken = $('meta[name="csrf-token"]').attr('content');
			},
			data:{
				listNotifications:[],
				listBilling: [],
				auxToken: '',
				auxDisabled: false,
			},
			filters:{
				manlen:function(value) {
					if (value.length > 60)
						return value.substring(0,59) + '...';
					else
						return value;
				}
			},
			methods:{
				// Get the user's billing information.
				getBillingInformation: function (event) {
					var vm = this;
					var id_user = parseInt(event.target.value);
					vm.listBilling = [];
					if (id_user > 0) {
						vm.auxDisabled = true;
						axios({
							method: 'get',
							url: '/users/'+ id_user +'/get_billing_information.json',
							data: {authenticity_token:vm.auxToken},
							responseType: 'json'
						})
						.then(function (response) {
							vm.listBilling = response.data.billing_information
						})
						.catch(function (error) {
							console.log('error al cargar la información fiscal');
							console.log(error);
						})
						.then(function () {
							vm.auxDisabled = false
						})
					}
				},
				getNotifications:function(){
					var vm = this, displayDrop = $('.dropdown-menu').css('display');

					if ( displayDrop == 'none' ) {
						axios({
							method:'get',
							url:'/notification_users/get_new_notifications.json',
							responseType:'json'
						})
						.then(function(response){
							console.log(response);
							vm.listNotifications = response.data;
							var lblNotification = document.getElementById('lbl-notification');
							lblNotification.innerHTML = vm.listNotifications.length;

						})
						.catch(function(error){
							console.log(error.response);
							console.log(error)
						})
						.then(function(){
							vm.showOrNot = false;
						})
					}
				}
			},
			//template: '<NotificationComponent :username="username" />',
			//components: { NotificationComponent } 
		});
	}	
});


// The above code uses Vue without the compiler, which means you cannot
// use Vue to target elements in your existing html templates. You would
// need to always use single file components.
// To be able to target elements in your existing html/erb templates,
// comment out the above code and uncomment the below
// Add <%= javascript_pack_tag 'hello_vue' %> to your layout
// Then add this markup to your html template:
//
// <div id='hello'>
//   {{message}}
//   <app></app>
// </div>


// import Vue from 'vue/dist/vue.esm'
// import App from '../app.vue'
//
// document.addEventListener('DOMContentLoaded', () => {
//   const app = new Vue({
//     el: '#hello',
//     data: {
//       message: "Can you say hello?"
//     },
//     components: { App }
//   })
// })
//
//
//
// If the project is using turbolinks, install 'vue-turbolinks':
//
// yarn add vue-turbolinks
//
// Then uncomment the code block below:
//
// import TurbolinksAdapter from 'vue-turbolinks'
// import Vue from 'vue/dist/vue.esm'
// import App from '../app.vue'
//
// Vue.use(TurbolinksAdapter)
//
// document.addEventListener('turbolinks:load', () => {
//   const app = new Vue({
//     el: '#hello',
//     data: () => {
//       return {
//         message: "Can you say hello?"
//       }
//     },
//     components: { App }
//   })
// })
